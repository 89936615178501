import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import { useAuth } from '../../../../lib/AuthContext'
import { useAccount } from '../../../../lib/AccountContext'

import Skeleton from './Skeleton'

import Divider from '../../../Divider'
import UserInfo from '../../components/UserInfo'
import MyCourses from '../../components/MyCourses'
import ProfileCard from '../../components/ProfileCard'

const GET_COURSE = gql`
  query getCourses($accountId: String!, $limit: Float!, $offset: Float!) {
    getCourses(accountId: $accountId, limit: $limit, offset: $offset) {
      id
      title
      description
      courseVersionId
      progress
      versions
      latestVersion
      latestVersionAccessed
    }
  }
`

const Profile = () => {
  const {
    user: { role, firstName, lastName, profilePicture }
  } = useAuth()
  const [showProfile, setShowProfile] = useState(true)
  const { id: accountId } = useAccount()
  const { data, loading, error } = useQuery(GET_COURSE, {
    variables: {
      accountId,
      limit: 20,
      offset: 0
    }
  })

  useEffect(() => {
    if (firstName && lastName && profilePicture) {
      setShowProfile(false)
    }
  }, [firstName, lastName, profilePicture])

  if (loading) {
    return <Skeleton />
  }

  if (error) {
    return <p>Error</p>
  }

  console.log(data.getCourses)

  return (
    <React.Fragment>
      <UserInfo />
      {role !== 'owner' && (
        <>
          <Divider />
          <MyCourses courses={data.getCourses} />
        </>
      )}
      {showProfile && <ProfileCard />}
    </React.Fragment>
  )
}

export default Profile
