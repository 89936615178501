import React from 'react'

import clsx from 'clsx'

import Link from 'next/link'
import List from '@material-ui/core/List'
import { useRouter } from 'next/router'

import useStyles from './styles'

const MenuItems = ({ mobile = false, toggleMobileDrawer }) => {
  const classes = useStyles()
  const router = useRouter()

  return (
    <>
      <List className={clsx(classes.nav, mobile && classes.mobileNav)}>
        <Link href="/app" passHref>
          <a
            className={clsx(classes.navItem, {
              [classes.selected]: router.pathname === '/app'
            })}
            onClick={mobile && toggleMobileDrawer(false)}
          >
            Home
          </a>
        </Link>
        <Link href="/app/courses" passHref>
          <a
            className={clsx(classes.navItem, {
              [classes.selected]: router.pathname.startsWith('/app/courses')
            })}
            onClick={mobile && toggleMobileDrawer(false)}
          >
            Cursos
          </a>
        </Link>
        <Link href="/app/discussions" passHref>
          <a
            className={clsx(classes.navItem, {
              [classes.selected]: router.pathname.startsWith('/app/discussions')
            })}
            onClick={mobile && toggleMobileDrawer(false)}
          >
            Discussões
          </a>
        </Link>
        <Link href="/app/support" passHref>
          <a
            className={clsx(classes.navItem, {
              [classes.selected]: router.pathname.startsWith('/app/support')
            })}
            onClick={mobile && toggleMobileDrawer(false)}
          >
            Suporte
          </a>
        </Link>
      </List>
    </>
  )
}

export default MenuItems
