import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'

import Router from 'next/router'

import Drawer from '~/components/Menus/Drawer'
import DrawerMobile from '~/components/Menus/DrawerMobile'
import TransitionAlerts from '~/components/TransitionAlerts'

import useStyles from './styles'

import Appbar from '~/components/Menus/Appbar'

const AuthLayout = ({ children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMenu, setMobileMenu] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('learn49-token')
    if (!token) {
      Router.push('/')
    }
  }, [])

  const toggleMobileDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setMobileMenu(isOpen)
  }

  return (
    <Fade in>
      <div className={classes.root}>
        <CssBaseline />
        <Appbar
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          mobileMenu={setMobileMenu}
        />
        <Drawer />
        <DrawerMobile
          mobileMenu={mobileMenu}
          toggleMobileDrawer={toggleMobileDrawer}
        />
        <main className={classes.main} id="scrollableDiv">
          <div className={classes.content}>
            <Container maxWidth="lg" className={classes.containerLg}>
              <TransitionAlerts>
                Atenção! Devido a epidemia de covid-19 não estamos enviando
                brindes.
              </TransitionAlerts>
              {children}
            </Container>
          </div>
        </main>
      </div>
    </Fade>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthLayout
