import { createStyles, makeStyles } from '@material-ui/core/styles'

import { drawerWidth } from '../../Menus/Drawer/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    main: {
      flexGrow: 1,
      backgroundColor: '#fff',
      marginTop: 72,
      height: 'calc(100vh - 72px)',
      overflowY: 'scroll',
      [theme.breakpoints.up('md')]: {
        borderTopLeftRadius: 56
      }
    },
    content: {
      padding: '16px 0',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '32px 0'
      }
    },
    containerLg: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 9,
        paddingRight: 10
      }
    }
    // alert: {
    //   width: '100%',
    //   '& > * + *': {
    //     marginTop: theme.spacing(2)
    //   }
    // },
    // appBar: {
    //   marginLeft: drawerWidth,
    //   background: '#0F1561',
    //   height: 72,
    //   [theme.breakpoints.up('md')]: {
    //     width: `calc(100% - ${drawerWidth}px)`
    //   }
    // },
    // navLink: {
    //   color: 'rgba(255, 255, 255, 0.8)'
    // },
    // title: {
    //   color: theme.palette.text.primary
    // },
    // toolbar: {
    //   height: '100%',
    //   paddingLeft: 0,
    //   paddingRight: 8,
    //   [theme.breakpoints.down('sm')]: {
    //     display: 'none'
    //   }
    // },

    // avatar: {
    //   color: '#fff',
    //   backgroundColor: theme.palette.secondary.main,
    //   height: 40,
    //   width: 40
    // },
    // userActions: {
    //   display: 'flex',
    //   alignItems: 'center'
    // },
    // actions: {
    //   display: 'flex',
    //   alignItems: 'center'
    // },
    // notification: {
    //   marginRight: 16,
    //   color: '#fff'
    // },
    // badge: {
    //   minWidth: 16,
    //   width: 16,
    //   height: 16,
    //   color: '#fff',
    //   fontSize: 12,
    //   fontWeight: 'bold'
    // },
    // anchorOriginTopRightRectangle: {
    //   top: 3,
    //   right: 3
    // }
  })
)

export default useStyles
