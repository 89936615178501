import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    nav: {
      display: 'flex',
      alignItems: 'center'
    },
    mobileNav: {
      flexDirection: 'column',
      alignItems: 'normal'
    },
    navItem: {
      position: 'relative',
      marginLeft: 45,
      color: '#FFFFFF80',
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textDecoration: 'none',
      [theme.breakpoints.up('lg')]: {
        marginRight: 45
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 24,
        marginBottom: 32,
        color: '#fff',
        textTransform: 'capitalize'
      }
    },
    selected: {
      [theme.breakpoints.up('md')]: {
        color: '#fff',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: -10,
          height: 4,
          width: 4,
          borderRadius: '50%',
          background: theme.palette.secondary.main,
          left: '50%',
          transform: 'translateX(-50%)'
        }
      }
    }
  })
)

export default useStyles
