import React from 'react'
import propTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'

import logo from '../../../assets/logo-devpleno.svg'

import useStyles from './styles'

const Logo = ({ mobileMenu }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.wrapperLogo} container alignItems="center">
      <Hidden mdUp>
        <IconButton
          edge="start"
          color="inherit"
          className={classes.iconButton}
          onClick={() => mobileMenu(true)}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
      <div className={classes.divLogo}>
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
    </Grid>
  )
}

Logo.propTypes = {
  mobileMenu: propTypes.func
}

export default Logo
