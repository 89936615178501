import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { AppProps } from 'next/app'
import { ApolloClient, InMemoryCache } from 'apollo-boost'
import * as Sentry from '@sentry/node';

import { AccountProvider } from '~/lib/AccountContext'
import { AuthProvider } from '~/lib/AuthContext'
import { SnackbarProvider } from '~/lib/SnackbarContext'
import { DialogProvider } from '~/hook/useDialog'
import withData from '~/lib/apollo'
import '~/styles/styles.css'
import useStyles from '~/styles/styles'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { theme } from '~/styles/theme'

import AuthLayout from '~/components/_layouts/AuthLayout'

const AuthenticatedLayout = ({ children }) => (
  <AuthProvider>
    <AuthLayout>
      {children}
    </AuthLayout>
  </AuthProvider>
)

const DefaultLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.DefaultLayout} >
      {children}
    </div>
  )
}

type CustomAppProps = AppProps & { apollo: ApolloClient<InMemoryCache>, err: any }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}

const App: React.FC<CustomAppProps> = ({ Component, pageProps, apollo, router, err }) => {
  const { account } = pageProps

  const nonAuthenticate = [
    '/',
    '/404',
    '/app/access-denied',
    '/signup',
    '/forgot-password',
    '/confirm-email',
    '/confirmation/[tokenId]/[plainToken]',
    '/reset-password/[tokenId]/[plainToken]',
    '/create-password/[tokenId]/[plainToken]'
  ]

  const Layout = nonAuthenticate.includes(router.pathname) ? DefaultLayout : AuthenticatedLayout

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={apollo}>
        <AccountProvider account={account}>
          <DialogProvider>
            <SnackbarProvider>
              <Layout>
                <Component {...pageProps} apollo={apollo} err={err} />
              </Layout>
            </SnackbarProvider>
          </DialogProvider>
        </AccountProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
export default withData(App)
