import { createStyles, makeStyles } from '@material-ui/core/styles'

import { drawerWidth } from '../Drawer/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      marginLeft: drawerWidth,
      background: '#0F1561',
      height: 72,
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    toolbar: {
      height: '100%',
      paddingLeft: 0,
      paddingRight: 8,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    actions: {
      display: 'flex',
      alignItems: 'center'
    },
  })
)

export default useStyles
