import React from 'react'

import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const OpenCourseSkeleton = () => {
  return (
    <>
      <Skeleton
        animation="wave"
        variant="text"
        width={80}
        height={30}
        style={{
          marginTop: 20,
          marginBottom: 28,
          background: '#22276D'
        }}
      />

      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Skeleton
          animation="wave"
          variant="circle"
          width={56}
          height={56}
          style={{
            background: '#22276D'
          }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={190}
          height={42}
          style={{
            marginLeft: 16,
            background: '#22276D'
          }}
        />
      </Grid>
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={150}
        style={{
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={150}
        style={{
          marginTop: 16,
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={150}
        style={{
          marginTop: 16,
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={150}
        style={{
          marginTop: 16,
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
    </>
  )
}

export default OpenCourseSkeleton
