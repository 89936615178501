import React from 'react'
import {
  CircularProgress as MUICircularProgress,
  Typography
} from '@material-ui/core'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'

import useStyles from './styles'

const CircularProgress: React.FC<CircularProgressProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MUICircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={props.size}
        thickness={4}
      />
      <MUICircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={props.size}
        thickness={4}
        {...props}
      />
      <Typography className={classes.value}>{props.value}%</Typography>
    </div>
  )
}

export default CircularProgress

