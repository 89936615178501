import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Router from 'next/router'

import CircularProgress from '../../../CircularProgress'

import useStyles from './styles'

const CardCourse = ({ course, progress }) => {
  const classes = useStyles()
  const { id: courseId, title, latestVersion, latestVersionAccessed } = course
  const courseVersionId = latestVersionAccessed || latestVersion

  return (
    <Grid
      className={classes.root}
      container
      wrap="nowrap"
      onClick={() =>
        Router.push(
          '/app/courses/[courseId]/version/[courseVersionId]',
          `/app/courses/${courseId}/version/${courseVersionId}`
        )
      }
    >
      <Grid container alignItems="center">
        <CircularProgress
          variant="static"
          value={progress && progress.toFixed()}
          size={56}
        />
        <div className={classes.course}>
          <Typography className={classes.title}>{title}</Typography>
          {/* <Typography className={classes.info}>Módulo 01 - Lição 02</Typography> */}
        </div>
      </Grid>
      <ChevronRight className={classes.icon} />
    </Grid>
  )
}

CardCourse.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    latestVersion: PropTypes.string,
    latestVersionAccessed: PropTypes.string
  }).isRequired,
  progress: PropTypes.number.isRequired
}

export default CardCourse
