import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    DefaultLayout: {
      backgroundColor: '#fff',
      height: '100vh'
      //padding: '0 15px'
    }
  })
)

export default useStyles
