import React from 'react'
import propTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import User from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { useAuth } from '~/lib/AuthContext'

import useStyles from './styles'

const UserActions = ({ setAnchorEl }) => {
  const classes = useStyles()
  const {
    user: { profilePicture }
  } = useAuth()

  return (
    <div className={classes.userActions}>
      <Button
        className={classes.buttonClass}
        aria-controls="profile-menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar
          className={classes.avatar}
          src={profilePicture}
          alt="profile-picture"
        >
          <User />
        </Avatar>
        <div className={classes.divMore}>
          <ExpandMore className={classes.more} />
        </div>
      </Button>
    </div>
  )
}

UserActions.propTypes = {
  setAnchorEl: propTypes.func.isRequired
}

export default UserActions
