import React, { Fragment } from 'react'
import propTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'

import Notifications from '~/components/Notifications'
import MenuItems from '../components/MenuItems'
import UserActions from '../components/UserActions'
import MenuProfile from '../components/MenuProfile'

import useStyles from './styles'

import Logo from '../Logo'

const Appbar = ({ anchorEl, setAnchorEl, mobileMenu }) => {
  const classes = useStyles()

  return (
    <Fragment>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Hidden mdUp>
          <Logo mobileMenu={mobileMenu} />
        </Hidden>
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <MenuItems />
            <div className={classes.actions}>
              <Notifications />
              <UserActions setAnchorEl={setAnchorEl} />
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      <MenuProfile anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Fragment>
  )
}

Appbar.propTypes = {
  anchorEl: propTypes.object,
  setAnchorEl: propTypes.func.isRequired,
  mobileMenu: propTypes.func.isRequired
}

export default Appbar
