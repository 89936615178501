import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 16,
      marginTop: 16
    },
    header: {
      marginBottom: 16
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1
    },
    viewAll: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      fontSize: 14,
      textDecoration: 'none'
    }
  })
)

export default useStyles
