import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 16,
      background: '#22276D',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      '& + div': {
        marginTop: 16
      },
      cursor: 'pointer'
    },
    course: {
      marginLeft: 16
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1
      // marginBottom: 8
    },
    info: {
      fontSize: 14,
      color: '#fff',
      lineHeight: 1
    },
    icon: {
      color: '#fff',
      marginTop: -6,
      marginRight: -6
    }
  })
)

export default useStyles
