import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles(() =>
  createStyles({
    module: {
      padding: 16,
      background: '#22276D',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      '& + div': {
        marginTop: 16
      }
    },
    sortOrder: {
      fontSize: 14,
      fontWeight: 500,
      color: '#fff',
      lineHeight: 1,
      marginBottom: 8
    },
    lessonWrapper: {
      '& + div': {
        marginTop: 16
      }
    },
    moduleTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1,
      marginBottom: 16
    },
    icon: {
      color: '#fff',
      marginTop: -6,
      marginRight: -6,
      marginLeft: 'auto'
    }
  })
)

export default useStyles
