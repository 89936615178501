import { useCallback, useState, useRef } from 'react'
import useDocumentEvent from './useDocumentEvent'

const useNotification = (initialState = false) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(initialState)

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return
      }
      setIsOpen(false)
    },
    [ref]
  )

  const handleKeydownEscape = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }, [])

  useDocumentEvent([
    { type: 'click', callback: handleClickOutside },
    { type: 'keydown', callback: handleKeydownEscape }
  ])

  return [ref, isOpen, setIsOpen]
}

export default useNotification
