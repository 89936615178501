import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 16,
      marginTop: 16,
      background: '#22276D',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      transition: '1s all'
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1,
      marginBottom: 8
    },
    profileProgress: {
      marginTop: 8,
      fontSize: 14,
      color: '#fff',
      lineHeight: 1
    },
    wrapperDiv: {
      marginTop: 16
    },
    typo: {
      marginLeft: 8,
      color: '#fff',
      fontSize: 14
    },
    container: {
      marginTop: 11
    },
    edit: {
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        textTransform: 'capitalize'
      }
    },
    unCheckedIcon: {
      color: '#5a5d92',
      marginRight: 8
    },
    checkedIcon: {
      color: theme.palette.secondary.main,
      marginRight: 8
    }
  })
)

export default useStyles
