import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'

import { Typography, Tooltip } from '@material-ui/core'
import { CheckCircle, Lock } from '@material-ui/icons'

import useStyles from './styles'

const Lesson = ({ lesson }) => {
  const classes = useStyles()
  const router = useRouter()
  const { courseId, courseVersionId } = router.query
  const { id: lessonId, title, completed, releaseOnDate } = lesson

  if (new Date(releaseOnDate) > new Date()) {
    const releaseOnDateFormatted = format(
      parseISO(releaseOnDate),
      'dd/MM/yyyy',
      { locale: pt }
    )

    return (
      <Tooltip
        title={`Disponível em ${releaseOnDateFormatted}`}
        placement="bottom-start"
      >
        <Typography className={classes.lock} variant="h4">
          <Lock className={classes.unCheckedIcon} />
          {title}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <Link
      href="/app/courses/[courseId]/version/[courseVersionId]/learn/[lessonId]"
      as={`/app/courses/${courseId}/version/${courseVersionId}/learn/${lessonId}`}
      passHref
    >
      <a className={classes.lesson}>
        <CheckCircle
          className={completed ? classes.checkedIcon : classes.unCheckedIcon}
        />
        {title}
      </a>
    </Link>
  )
}

Lesson.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    completed: PropTypes.bool,
    releaseOnDate: PropTypes.string
  }).isRequired
}

export default Lesson
