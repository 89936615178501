import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            width: 330,
            color: '#fff',
            backgroundColor: theme.palette.background.default,
            height: 'inherit'
        },
        top: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
            alignItems: 'center'
        },
        clearIcon: {
            marginLeft: 20
        },
        hr: {
            backgroundColor: '#FFFFFF33',
            width: '18.5rem',
            height: 1,
            border: 0,
            marginTop: 0
        }
    })
)

export default useStyles
