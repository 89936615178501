import React, { useCallback, useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Typography,
  LinearProgress as MUILinearProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Divider from '../../../Divider'

import { useRouter } from 'next/router'

import { useAuth } from '../../../../lib/AuthContext'

import useStyles from './styles'

const LinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 20,
    backgroundColor: '#5034A9'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main
  }
}))(MUILinearProgress)

const Profile = () => {
  const classes = useStyles()
  const {
    user: { firstName, lastName, profilePicture }
  } = useAuth()
  const [progress, setProgress] = useState(0)
  const router = useRouter()

  const handleNavigate = useCallback(() => {
    router.push('/app/profile')
  }, [router])

  useEffect(() => {
    let completed = 0
    if (profilePicture) {
      completed += 1
    }

    if (firstName && lastName) {
      completed += 1
    }

    const newProgress = (completed / 2) * 100

    setProgress(newProgress.toFixed())
  }, [firstName, lastName, profilePicture, setProgress])

  return (
    <>
      <Divider />
      <div className={classes.root}>
        <Typography className={classes.title}>Seu Perfil</Typography>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={progress}
        />
        <Typography className={classes.profileProgress}>
          {`Seu perfil está ${progress}% completo.`}
        </Typography>
        <Grid container direction="column" className={classes.wrapperDiv}>
          <Grid container alignItems="center">
            <CheckCircle
              className={
                profilePicture ? classes.checkedIcon : classes.unCheckedIcon
              }
            />
            <Typography className={classes.typo}>Foto de perfil</Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.wrapperDiv}>
          <Grid container alignItems="center">
            <CheckCircle
              className={
                firstName && lastName
                  ? classes.checkedIcon
                  : classes.unCheckedIcon
              }
            />
            <Typography className={classes.typo}>Nome completo</Typography>
          </Grid>
        </Grid>
        {router.pathname !== '/app/profile' && (
          <Button
            className={classes.edit}
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleNavigate}
          >
            Editar Perfil
          </Button>
        )}
      </div>
    </>
  )
}

export default Profile
