import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ExitToApp from '@material-ui/icons/ExitToApp'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import { useRouter } from 'next/router'
import { useAuth } from '~/lib/AuthContext'

const MenuProfile = ({ anchorEl, setAnchorEl, mobile, toggleMobileDrawer }) => {
  const { signOut } = useAuth()
  const router = useRouter()

  const isMenuOpen = Boolean(anchorEl)

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const toggleMobile = () => toggleMobileDrawer(false)

  const handleNavigate = useCallback(() => {
    !mobile && setAnchorEl(null)
    mobile && toggleMobile()
    router.push('/app/profile')
  }, [router, setAnchorEl])

  const handleSignOut = useCallback(async () => {
    !mobile && setAnchorEl(null)
    mobile && toggleMobile()
    await signOut()
    router.push('/')
  }, [router, setAnchorEl, signOut])

  if (mobile) {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: 24,
          fontSize: 14,
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
      >
        <div
          onClick={handleNavigate}
          style={{ marginBottom: 24, marginTop: 20 }}
        >
          Perfil
        </div>
        <div
          onClick={handleSignOut}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Sair
          <ExitToApp style={{ marginRight: 16 }} />
        </div>
      </div>
    )
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="profile-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleNavigate} style={{ fontWeight: 500 }}>
        Perfil
      </MenuItem>
      <MenuItem onClick={handleSignOut} style={{ fontWeight: 500 }}>
        Sair
        <ExitToApp style={{ marginLeft: 16 }} />
      </MenuItem>
    </Menu>
  )
}

MenuProfile.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func
}

export default MenuProfile
