import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { Grid, Typography } from '@material-ui/core'

import CardCourse from '../CardCourse'

import useStyles from './styles'

const MyCourses = ({ courses }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      alignItems="center"
      direction="column"
    >
      <Grid
        className={classes.header}
        container
        justify="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Meus Cursos</Typography>
        <Link href="/app/courses" passHref>
          <a className={classes.viewAll}>Ver Todos</a>
        </Link>
      </Grid>
      {courses.map((course) => (
        <CardCourse
          key={course.id}
          course={course}
          progress={course.progress}
        />
      ))}
    </Grid>
  )
}

MyCourses.propTypes = {
  courses: PropTypes.array.isRequired
}

export default MyCourses
