import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        display: 'inline',
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#0F1561',
      borderRight: 0,
      paddingLeft: 16,
      paddingRight: 16
    },
    viewProfileButton: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 19,
      marginBottom: 27,
      cursor: 'pointer',
      textDecoration: 'none'
    },
    course: {
      marginLeft: 16
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1
    },
    info: {
      fontSize: 14,
      color: '#fff',
      lineHeight: 1
    },
    modules: {
      overflow: 'scroll',
      flex: 1,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      marginBottom: 16
    },
    module: {
      padding: 16,
      background: '#22276D',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      '& + div': {
        marginTop: 16
      }
    },
    sortOrder: {
      fontSize: 14,
      fontWeight: 500,
      color: '#fff',
      lineHeight: 1,
      marginBottom: 8
    },
    moduleTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1,
      marginBottom: 16
    },
    icon: {
      color: '#fff',
      marginTop: -6,
      marginRight: -6,
      marginLeft: 'auto'
    },
    courseStatus: {
      marginBottom: 24
    },
    lesson: {
      display: 'flex',
      textDecoration: 'none',
      color: '#fff',
      fontSize: 14,
      alignItems: 'center',
      '& + a': {
        marginTop: 16
      }
    },
    unCheckedIcon: {
      color: '#5a5d92',
      marginRight: 8
    },
    checkedIcon: {
      color: theme.palette.secondary.main,
      marginRight: 8
    }
  })
)

export default useStyles
