import React from 'react'

import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const ProfileSkeleton = () => {
  return (
    <>
      <Grid container alignItems="center" direction="column">
        <Skeleton
          animation="wave"
          variant="circle"
          width={80}
          height={80}
          style={{
            background: '#22276D',
            marginTop: 28
          }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={110}
          style={{
            marginTop: 14,
            background: '#22276D'
          }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={190}
          height={35}
          style={{
            marginTop: 8,
            marginBottom: 16,
            background: '#22276D'
          }}
        />
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 14 }}
      >
        <Skeleton
          animation="wave"
          variant="text"
          width={95}
          height={30}
          style={{
            background: '#22276D'
          }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={95}
          height={25}
          style={{
            background: '#22276D'
          }}
        />
      </Grid>
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={88}
        style={{
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={88}
        style={{
          marginTop: 16,
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        width="100%"
        height={88}
        style={{
          marginTop: 16,
          background: '#22276D',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20
        }}
      />
    </>
  )
}

export default ProfileSkeleton
