import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  Slide,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Badge
} from '@material-ui/core'
import { NotificationsOutlined as NotificationsIcon } from '@material-ui/icons'

import { handleTimeDifference } from '../../utils/handleTimeDifference'

import useNotification from '../../hook/useNotification'
import { useAccount } from '../../lib/AccountContext'
import { useAuth } from '../../lib/AuthContext'

import useStyles from './styles'

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead(
    $accountId: String!
    $notificationId: String!
  ) {
    markNotificationAsRead(
      accountId: $accountId
      notificationId: $notificationId
    ) {
      id
      message
      read
      type
      data
    }
  }
`

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation markAllNotificationsAsRead(
    $accountId: String!
    $notificationsId: [String!]!
  ) {
    markAllNotificationsAsRead(
      accountId: $accountId
      notificationsId: $notificationsId
    ) {
      id
      message
      read
      type
      data
    }
  }
`

const Notification = ({ read, notification, setOpen }) => {
  const classes = useStyles({ read })
  const router = useRouter()
  const {
    id: notificationId,
    accountId,
    message,
    data,
    type,
    user,
    createdAt
  } = notification
  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [
      {
        query: GET_ALL_NOTIFICATIONS,
        variables: {
          accountId
        }
      }
    ]
  })

  const handleRedirectNotification = useCallback(() => {
    if (!read) {
      markNotificationAsRead({
        variables: {
          accountId,
          notificationId
        }
      })
    }

    if (type === 'thread_answer' || type === 'mention') {
      const { thread_id } = JSON.parse(data)

      //Checar com Túlio problema do courseVersionID
      router.push(
        '/app/discussions/[discussionId]',
        `/app/discussions/${thread_id}`
      )
      setOpen(false)
    }
  }, [
    markNotificationAsRead,
    accountId,
    notificationId,
    type,
    data,
    router,
    read,
    setOpen
  ])

  const handleNotificationMessage = useCallback(() => {
    if (type === 'thread_answer') {
      return (
        <>
          <strong>{`${user.firstName} ${user.lastName}`}</strong>{' '}
          {`respondeu a sua discussão ${message}.`}
        </>
      )
    } else if (type === 'mention') {
      return (
        <>
          <strong>{`${user.firstName} ${user.lastName}`}</strong>{' '}
          {`mencionou você na discussão ${message}.`}
        </>
      )
    }
  }, [type, user, message])

  return (
    <Grid
      className={classes.notification}
      container
      direction="column"
      onClick={handleRedirectNotification}
    >
      <Grid className={classes.content} container wrap="nowrap">
        <Avatar className={classes.avatar} src={user.profilePicture} />
        <span className={classes.message}>{handleNotificationMessage()}</span>
      </Grid>
      <Grid container justify="flex-end">
        <Typography className={classes.createdAt} variant="subtitle1">
          {`há ${handleTimeDifference(new Date(), createdAt)}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

Notification.propTypes = {
  read: PropTypes.bool.isRequired,
  notification: PropTypes.shape({
    accountId: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.object,
    message: PropTypes.string,
    user: {
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profilePicture: PropTypes.string
    },
    createdAt: PropTypes.string
  }).isRequired,
  setOpen: PropTypes.func.isRequired
}

const NOTIFICATION_ADDED = gql`
  subscription notificationAdded($userId: String!) {
    notificationAdded(userId: $userId) {
      id
      accountId
      message
      read
      type
      data
      user {
        firstName
        lastName
        profilePicture
      }
      createdAt
    }
  }
`

const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($accountId: String!) {
    getAllNotifications(accountId: $accountId) {
      id
      accountId
      message
      read
      type
      data
      user {
        firstName
        lastName
        profilePicture
      }
      createdAt
    }
  }
`

const Notifications = () => {
  const classes = useStyles()
  const [notificationRef, open, setOpen] = useNotification()
  const { id: accountId } = useAccount()
  const {
    user: { id: userId }
  } = useAuth()
  const { data, subscribeToMore } = useQuery(GET_ALL_NOTIFICATIONS, {
    variables: {
      accountId
    }
  })
  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ,
    {
      refetchQueries: [
        {
          query: GET_ALL_NOTIFICATIONS,
          variables: {
            accountId
          }
        }
      ]
    }
  )

  const handleReadAllNotifications = useCallback(() => {
    const notificationsId = data.getAllNotifications
      .filter((notification) => !notification.read)
      .map((notification) => notification.id)

    if (notificationsId.length === 0) {
      return
    }

    markAllNotificationsAsRead({
      variables: {
        accountId,
        notificationsId
      }
    })
  }, [data, markAllNotificationsAsRead, accountId])

  const hasUnread = useMemo(
    () =>
      data &&
      data.getAllNotifications.filter(
        (notification) => notification.read === false
      ),
    [data]
  )

  useEffect(() => {
    if (userId) {
      subscribeToMore({
        document: NOTIFICATION_ADDED,
        variables: { userId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const newNotification = subscriptionData.data.notificationAdded
          return Object.assign({}, prev, {
            getAllNotifications: [newNotification, ...prev.getAllNotifications]
          })
        }
      })
    }
  }, [subscribeToMore, userId])

  return (
    <div>
      <IconButton className={classes.iconButton} onClick={() => setOpen(!open)}>
        <Badge
          badgeContent={hasUnread && hasUnread.length}
          color="secondary"
          classes={{
            badge: classes.badge,
            anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Slide
        ref={notificationRef}
        in={open}
        direction="left"
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <Grid
            className={classes.header}
            container
            justify="space-between"
            alignItems="center"
          >
            <Typography className={classes.title} variant="h2">
              Não lidas
            </Typography>
            <button
              className={classes.button}
              onClick={handleReadAllNotifications}
            >
              Ler tudo
            </button>
          </Grid>
          <Grid className={classes.wrapper} container direction="column">
            {data &&
              data.getAllNotifications
                .filter((notification) => !notification.read)
                .map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    setOpen={setOpen}
                  />
                ))}
          </Grid>
          <Grid
            className={classes.header}
            container
            justify="space-between"
            alignItems="center"
          >
            <Typography className={classes.title} variant="h2">
              Lidas
            </Typography>
          </Grid>
          <Grid className={classes.wrapper} container direction="column">
            {data &&
              data.getAllNotifications
                .filter((notification) => notification.read)
                .map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    setOpen={setOpen}
                    read
                  />
                ))}
          </Grid>
        </div>
      </Slide>
    </div>
  )
}

export default Notifications
