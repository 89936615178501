import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    userActions: {
      display: 'flex',
      alignItems: 'center'
    },
    buttonClass: {
      position: 'relative',
      width: 80
    },
    avatar: {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
      height: 40,
      width: 40,
      position: 'absolute',
      left: 12,
      zIndex: 1
    },
    divMore: {
      position: 'absolute',
      right: 0,
      background: '#22276D',
      borderRadius: 8,
      height: 40,
      width: 56,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 6
    },
    more: {
      color: '#fff',
      fontSize: 20
    }
  })
)

export default useStyles
