import React from 'react'

import useStyles from './styles'

const Divider = () => {
  const classes = useStyles()
  return <hr className={classes.divider} />
}

export default Divider
