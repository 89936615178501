import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useAuth } from '../../../../lib/AuthContext'
import useStyles from './styles'

const UserInfo = ({ mobile = false }) => {
  const classes = useStyles()
  const {
    user: { firstName, lastName, profilePicture }
  } = useAuth()
  return (
    <Grid
      className={clsx(classes.root, mobile && classes.mobile)}
      container
      alignItems="center"
      direction="column"
    >
      <Avatar
        className={clsx(classes.avatar, mobile && classes.mobileAvatar)}
        src={profilePicture}
        alt="profile_picture"
      />
      <div className={clsx(classes.about, mobile && classes.mobileAbout)}>
        <Typography
          className={clsx(classes.welcome, mobile && classes.mobileWelcome)}
        >
          Seja bem-vindo!
        </Typography>
        <Typography className={classes.username}>
          {`${firstName || ''} ${lastName || ''}`}
        </Typography>
      </div>
    </Grid>
  )
}

UserInfo.propTypes = {
  mobile: PropTypes.bool
}

export default UserInfo
