import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: 56
    },
    top: {
      color: '#5034A9'
    },
    bottom: {
      color: theme.palette.primary.main,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0
    },
    value: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontSize: 14,
      fontWeight: 'bold'
    }
  })
)

export default useStyles
