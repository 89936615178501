import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles((theme) =>
  createStyles({
    lesson: {
      display: 'flex',
      textDecoration: 'none',
      color: '#fff',
      fontSize: 14,
      alignItems: 'center'
    },
    lock: {
      display: 'flex',
      textDecoration: 'none',
      color: '#fff',
      fontSize: 14,
      alignItems: 'center'
    },
    unCheckedIcon: {
      color: '#5a5d92',
      marginRight: 8
    },
    checkedIcon: {
      color: theme.palette.secondary.main,
      marginRight: 8
    }
  })
)

export default useStyles
