import React from 'react'
import propTypes from 'prop-types'

import UserInfo from '../components/UserInfo'
import ProfileCard from '../components/ProfileCard'
import MenuItems from '../components/MenuItems'
import MenuProfile from '../components/MenuProfile'
import Notifications from '~/components/Notifications'
import Drawer from '@material-ui/core/Drawer'
import ClearIcon from '@material-ui/icons/Clear'

import useStyles from './styles'

const MobileDrawer = ({ mobileMenu, toggleMobileDrawer }) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor={'left'}
      open={mobileMenu}
      onClose={toggleMobileDrawer(false)}
    >
      <div className={classes.list} role="presentation">
        <div className={classes.top}>
          <ClearIcon
            className={classes.clearIcon}
            onClick={toggleMobileDrawer(false)}
          />
          <Notifications />
        </div>
        <UserInfo mobile />
        <ProfileCard />
        <MenuItems mobile toggleMobileDrawer={toggleMobileDrawer} />
        <hr className={classes.hr} />
        <MenuProfile mobile toggleMobileDrawer={toggleMobileDrawer} />
      </div>
    </Drawer>
  )
}

MobileDrawer.propTypes = {
  mobileMenu: propTypes.bool.isRequired,
  toggleMobileDrawer: propTypes.func.isRequired,
}

export default MobileDrawer
