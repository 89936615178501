import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { ChevronLeft } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import ModuleItem from '../ModuleItem'

import CircularProgress from '../../../CircularProgress'
import Skeleton from './Skeleton'
import { useAccount } from '../../../../lib/AccountContext'

import useStyles from './styles'

const GET_COURSE = gql`
  query getCourse(
    $accountId: String!
    $courseId: String!
    $courseVersionId: String!
    $limit: Int
    $offset: Int
  ) {
    getCourse(accountId: $accountId, courseId: $courseId) {
      id
      title
      description
      progress
    }
    getCourseModules(
      accountId: $accountId
      courseVersionId: $courseVersionId
      limit: $limit
      offset: $offset
    ) {
      id
      title
      isActive
      baseId
      courseVersionId
      sortOrder
      lessons {
        id
        title
        accountId
        releaseOnDate
        completed
      }
    }
  }
`

const Course = () => {
  const classes = useStyles()
  const router = useRouter()
  const { id: accountId } = useAccount()
  const { courseId, courseVersionId } = router.query
  const { data, loading, error } = useQuery(GET_COURSE, {
    variables: {
      accountId,
      courseId,
      courseVersionId,
      limit: 50,
      offset: 0
    }
  })

  if (loading) {
    return <Skeleton />
  }

  if (error) {
    return <p>error</p>
  }

  return (
    <React.Fragment>
      <Link href="/app" passHref>
        <a className={classes.viewProfileButton}>
          <ChevronLeft />
          <span>Voltar</span>
        </a>
      </Link>
      <Grid className={classes.courseStatus} container alignItems="center">
        <CircularProgress
          variant="static"
          value={data.getCourse.progress && data.getCourse.progress.toFixed()}
          size={56}
        />
        <div className={classes.course}>
          <Link
            href="/app/courses/[courseId]/version/[courseVersionId]"
            as={`/app/courses/${courseId}/version/${courseVersionId}`}
            passHref
          >
            <a className={classes.title} variant="h2">
              {data.getCourse.title}
            </a>
          </Link>
        </div>
      </Grid>
      <div className={classes.modules}>
        {data.getCourseModules.map((module) => {
          if (module.lessons.length > 0) {
            return <ModuleItem key={module.id} module={module} />
          }
        })}
      </div>
    </React.Fragment>
  )
}

export default Course
