import React from 'react'
import { useRouter } from 'next/router'
import { Scrollbars } from 'react-custom-scrollbars'

import { Drawer } from '@material-ui/core'

import Course from '../components/Course'
import Logo from '../Logo'

import Profile from '../components/Profile'

import useStyles from './styles'

const SideBar = () => {
  const classes = useStyles()
  const router = useRouter()

  const pathname = router.pathname
  let currentMenu = <Profile />

  if (pathname.startsWith('/app/courses/[courseId]')) {
    currentMenu = <Course />
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <Scrollbars autoHide>
        <Logo />
        {currentMenu}
      </Scrollbars>
    </Drawer>
  )
}

export default SideBar
