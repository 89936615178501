import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 28,
      marginBottom: 16
    },
    mobile: {
      flexDirection: 'row',
      padding: '0 16px'
    },
    avatar: {
      height: 80,
      width: 80,
      border: '4px solid #fff',
      background: '#fff',
      marginBottom: 16
    },
    mobileAvatar: {
      height: '40pt !important',
      width: '40pt !important',
      marginBottom: '0 !important'
    },
    about: {
      textAlign: 'center'
    },
    mobileAbout: {
      paddingLeft: 14,
      textAlign: 'unset'
    },
    welcome: {
      color: '#fff',
      marginBottom: 12,
      fontSize: 14,
      lineHeight: 1
    },
    mobileWelcome: {
      marginBottom: 4
    },
    username: {
      color: '#fff',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1
    }
  })
)

export default useStyles
