import { createStyles, makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 316

const useStyles = makeStyles((theme) =>
  createStyles({
    viewProfileButton: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 19,
      marginBottom: 27,
      cursor: 'pointer',
      textDecoration: 'none'
    },
    course: {
      marginLeft: 16
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 1,
      textDecoration: 'none'
    },
    modules: {
      // overflow: 'scroll',
      flex: 1,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      marginBottom: 16
    },
    courseStatus: {
      marginBottom: 24
    }
  })
)

export default useStyles
