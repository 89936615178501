import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      ml: 424,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  overrides: {
    MuiSnackbar: {
      // root: {
      //   boxShadow: '0px 0px 6px #00000033'
      // }
    },
    MuiTab: {
      root: {
        color: '#263238',
        textTransform: 'none',
        fontSize: 16,
        padding: '12px 16px',
        '&$selected': {
          fontWeight: 'bold'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500
      }
    },
    MuiIconButton: {
      root: {
        height: 36,
        width: 36,
        padding: 0
      }
    },
    MuiButton: {
      root: {
        height: 42,
        borderRadius: 8,
        fontWeight: 'bold',
        boxShadow: 'none'
      }
    }
  },
  palette: {
    background: {
      default: '#0F1561'
    },
    text: {
      primary: '#263238'
    },
    primary: {
      main: '#AA25FF',
      light: '#e161ff',
      dark: '#7300cb'
    },
    secondary: {
      main: '#00BB42',
      light: '#A1E7C1',
      dark: '#00890d'
    }
  },
  typography: {
    fontFamily: '"Quicksand"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
})
