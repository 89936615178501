import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      width: 48,
      height: 4,
      borderRadius: 4,
      background: '#FFFFFF33',
      border: 0,
      marginTop: 0,
      marginBottom: 0
    }
  })
)

export default useStyles
