import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperLogo: {
      height: 72
    },
    divLogo: {
      textAlign: 'center',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-2rem'
      }
    },
    iconButton: {
      marginLeft: '1rem'
    },
    logo: {
      height: 17,
      marginTop: '0.4rem',
      [theme.breakpoints.up('md')]: {
        width: 151,
        height: 'unset',
        marginTop: 'unset'
      }
    }
  })
)

export default useStyles
