import React, { useState } from 'react'

import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import CancelIcon from '@material-ui/icons/Cancel'

import useStyles from './styles'

const TransitionAlerts = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <Collapse in={open}>
      <Alert
        severity="warning"
        classes={{
          root: classes.root,
          action: classes.closeIcon,
          message: classes.message
        }}
        icon={
          <WarningRoundedIcon
            fontSize="inherit"
            classes={{ root: classes.alertIcon }}
          />
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CancelIcon />
          </IconButton>
        }
      >
        {children}
      </Alert>
    </Collapse>
  )
}

export default TransitionAlerts
