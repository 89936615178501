import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const client = useApolloClient()
  const [data, setData] = useState({
    token: '',
    user: {}
  })

  useEffect(() => {
    const token = localStorage.getItem('learn49-token')
    const user = localStorage.getItem('learn49-user')

    if (token && user) {
      setData({ token, user: JSON.parse(user) })
    } else {
      setData({ token: '', user: {} })
    }
  }, [])

  const signOut = useCallback(async () => {
    await client.cache.reset()

    localStorage.removeItem('learn49-token')
    localStorage.removeItem('learn49-user')
  }, [client])

  const updateAuthUser = useCallback(
    (user) => {
      localStorage.setItem(
        'learn49-user',
        JSON.stringify({ ...user, role: data.user.role })
      )

      setData({
        token: data.token,
        user: {
          ...user,
          role: data.user.role
        }
      })
    },
    [data.token, data.user.role]
  )

  return (
    <AuthContext.Provider value={{ user: data.user, signOut, updateAuthUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
