import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 72,
      right: 0,
      width: 300,
      height: 'calc(100vh - 72px)',
      boxShadow: '0px 0px 20px #1016504D',
      background: '#fff',
      zIndex: 10,
      padding: '16px 16px 0',
      overflow: 'scroll'
    },
    header: {
      marginBottom: 16
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.text.primary
    },
    iconButton: {
      marginRight: 8,
      color: '#fff'
    },
    badge: {
      minWidth: 16,
      width: 16,
      height: 16,
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold'
    },
    anchorOriginTopRightRectangle: {
      top: 3,
      right: 3
    },
    button: {
      background: 'transparent',
      border: 0,
      outline: 0,
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 'bold'
    },
    avatar: {
      width: 24,
      height: 24
    },
    content: {
      marginBottom: 12
    },
    message: {
      fontSize: 12,
      marginLeft: 8,
      color: theme.palette.text.primary
    },
    createdAt: {
      color: '#546E7A',
      fontSize: 12
    },
    wrapper: {
      margin: '-8px 0 24px 0'
    },
    notification: {
      cursor: 'pointer',
      background: (props) => (props.read ? '#fff' : '#E2E3EC99'),
      border: (props) => (props.read ? '1px solid #CFD8DC' : 0),
      padding: 8,
      margin: '8px 0',
      borderRadius: 4
    }
  })
)

export default useStyles
