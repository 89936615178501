import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

import Lesson from '../Lesson'

import useStyles from './styles'

const ModuleItem = ({ module }) => {
  const { lessons } = module
  const classes = useStyles()
  return (
    <Grid className={classes.module} container>
      <Grid container>
        <div>
          <Typography className={classes.sortOrder}>
            {`Módulo ${module.sortOrder}`}
          </Typography>
          <Typography className={classes.moduleTitle}>
            {module.title}
          </Typography>
        </div>
        <ChevronRight className={classes.icon} />
      </Grid>
      <Grid container direction="column">
        {lessons.map((lesson) => (
          <div className={classes.lessonWrapper} key={lesson.id}>
            <Lesson lesson={lesson} />
          </div>
        ))}
      </Grid>
    </Grid>
  )
}

ModuleItem.propTypes = {
  module: PropTypes.shape({
    title: PropTypes.string,
    sortOrder: PropTypes.number,
    lessons: PropTypes.array
  }).isRequired
}

export default ModuleItem
