import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid rgb(255, 193, 7)',
      fontWeight: 'bold',
      backgroundColor: 'rgb(255, 248, 225)',
      borderRadius: 8,
      color: '#455A64',
      marginBottom: 24,
      [theme.breakpoints.down('sm')]: {
        padding: '8px 10px'
      }
    },
    alertIcon: {
      color: '#FFC107'
    },
    message: {
      marginTop: 1
    },
    closeIcon: {
      color: '#FFC107',
      padding: '3px 0',
      display: 'block',
      marginRight: 0,
      marginTop: '-2px'
    }
  })
)

export default useStyles
